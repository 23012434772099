import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import BannerLanding from '../components/BannerLanding'

import christie from '../assets/images/christie.jpg'
import jenny from '../assets/images/jenny.jpg'
import etrita from '../assets/images/etrita.jpg'
import mandy from '../assets/images/mandy.jpg'
import melissa from '../assets/images/melissa.jpg'
import nicole from '../assets/images/nicole.jpg'
import susanna from '../assets/images/susanna.jpg'
import rose from '../assets/images/rose.png'
import daniela from '../assets/images/daniela.jpeg'
import christine from '../assets/images/Christine.jpeg'

const Faculty = props => (
  <Layout>
    <Helmet>
      <title>The Coleman School of Dance</title>
      <meta name="description" content="Landing Page" />
    </Helmet>

    <BannerLanding header="Faculty" subheader="Meet The Teachers" />

    <div id="main">
      <section id="two" className="spotlights">
        <section>
          <Link to="/generic" className="image">
            <img src={christie} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Christina Coleman</h3>
                <h4>Director, Teacher</h4>
              </header>
              <p>
                <b>Christie Coleman</b> is a dancer and choreographer with a
                Masters in Education for Dance from NYU. Her choreography,
                highlighting social issues through dance, has been performed
                internationally and is acclaimed by UNESCO. She has worked with
                National Ballet of Kosovo premiering two evening-length works.
                Christie was raised within The Coleman School of Dance and
                founded Illuminations Dance Company. In addition to the Coleman
                School of Dance, Christie taught dance at Achievement First
                Charter School in East New York, New Rochelle High School, and
                Sacred Heart University.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={jenny} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Jenny Coleman</h3>
                <h4>Former Director, Teacher</h4>
              </header>
              <p>
                <b>Jenny Coleman</b> entered the dance world at the age of four
                in her mother's studio. As a teenager, she received a
                scholarship to study at The Governor's School of the arts in PA.
                Jenny received a Master's in Dance/Movement Therapy from
                Hahnemann Medical (now Drexel University). After working as a
                dance therapist and having children, she returned to her true
                professional love: teaching dance, which has enthralled her for
                the last 30 years. While teaching, Jenny danced in Connecticut
                Ballet Theatre's productions of The Nutcracker and in Classics
                Dance Theatre's productions of Little Women, Cinderella, and
                Anne Frank.
              </p>
            </div>
          </div>
        </section>


                  
        <section>
          <Link to="/generic" className="image">
            <img src={etrita} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Etrita Abdullahu</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Etrita Abdullahu</b> trained at “Prenk Jakova” Music High
                School as a ballet major, and performed with the National Ballet
                of Kosovo where she had the opportunity to work with
                international choreographers and travel with the company. She
                won the Grand Prix Award at The Arts Festival in Dubai. At Fini
                Dance Festival she won a scholarship to come to New York. Here,
                she danced with Michael Mao Company. She trained in the American
                Ballet Theater (ABT) collegiate summer intensive and the Joffrey
                Ballet School. She is now on her third year as a trainee with
                Joffrey. Her love for dance keeps her dancing and passing this
                passion to the younger generation.
              </p>
            </div>
          </div>
        </section>
                  
        <section>
          <Link to="/generic" className="image">
            <img src={rose} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Rose Barbantan</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Rose Barbantan</b> is from the village of Barbentane in South of France. She began
training at the Regional Dance Conservatory in Avignon, France. As a finalist in the Youth America Grand Prix in
Paris she met Annarella Sanchez, the Director of the International Conservatory of
Dance in Portugal and at the age of 15 moved to Portugal to pursue her desire to
dance. She performed in Got Talent Portugal, and in the Youth America
Grand Prix in New York. At age 16 she began training in the Graham technique at
the Paris Marais Dance School with Maggie Boogaart and soon moved to New
York to attend the Certificate Program at The Ailey School. She performed “Memoria”, staged by Rehearsal Director,
Ronni Favors, alongside the Alvin Ailey American Dance Theatre Company and
Ailey II, at New York City Center. As a member of the Ailey Student Performance
Group she has worked with choreographers such as Brice Mousset, Patrick Coker
and Hollie Wright. In addition to dance, Rose is quadrilingual in French,
Portuguese, Spanish and English and her distance education by CNED allowed her
to obtain her Bachelor Degree Literary baccalaureate diploma. Now Rose is working with
Jamel Gaines Creative Outlet, Shift dance company, Accent Dance Company, and
she was a part of « Table of Silence » 2022 with Jacqulyn Buglisi Dance Theater. 
              </p>
            </div>
          </div>
        </section>

          <section>
          <Link to="/generic" className="image">
            <img src={daniela} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Daniela O'Connor</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Daniela O'Connor</b> Daniela O'Connor was born in Buenos Aires, Argentina and raised in Miami, Florida. Her family migrated to the states in 1997 (where she grew her passion for dance) when she was 11 years old. Daniela is diverse in many different dance styles, such as ballet, jazz, hip-hop, lyrical, west african, latin dances, and much more. She obtained her BFA from new world school of the arts in 2009. She immediately moved to NY to fulfill her dream of traveling around the world while dancing, and also became the co-owner of the company LFX Dancers.
              </p>
            </div>
          </div>
        </section>
                  
        <section>
          <Link to="/generic" className="image">
            <img src={christine} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Christine Lewis</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Christine Lewis</b> studied dance at the Pacific School of Dance in Oregon in her youth taking ballet, jazz, modern, hip-hop, point, and partnering. She continued into the high school dance team, winning the state competition title as a senior captain and choreographer as well as selection to the All-State Team. She went on to participate in two dance groups at the collegiate level as she pursued her degree in fashion design, providing choreography and captain/coaching, performing and traveling weekly. While her career has been in design, she has continued her passion for dance with freelance choreography over the years, and is now excited to join the talented faculty at the Coleman School of Dance. She will bring expertise in the areas of execution, technique and style, and a philosophy of owning and determining one’s own evolution. 
              </p>
            </div>
          </div>
        </section>
  
        <section>
          <Link to="/generic" className="image">
            <img src={nicole} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Nicole Cruz</h3>
                <h4>Pointe Teacher</h4>
              </header>
              <p>
                <b>Nicole Cruz</b> was born in the Philippines. She pursued
                dance professionally with Ballet Philippines where she danced
                both principal and soloist roles. Nicole has also danced with
                Hong Kong Ballet and upon moving to the United States, danced
                with Connecticut Ballet, Zig Zog Ballet, Ballet New York and
                Ballet Memphis, where she spent three seasons dancing roles in
                Romeo and Juliet, Cinderella, Sleeping Beauty and Coppelia.
                Currently, Nicole teaches pilates at the Center For Movement in
                Scarsdale, Fiore Pilates in Mamaroneck and Pilates Symmetry in
                Stamford, CT. She also belongs to the faculty of Ballet Center
                in Stamford.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={mandy} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Mandy Kirschner Salva</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Mandy Kirschner Salva</b> was born and raised in Maryland.
                She started dancing at a young age and graduated from the
                Baltimore School for the Arts in 1995. She continued on to SUNY
                Purchase where she graduated cum laude and received a BFA in
                dance performance. In 2000 she joined the Merce Cunningham Dance
                Company where she performed and traveled the world for four
                years. She has worked with Ellen Cornfield and Liz Gerring, and
                was a member of the Stephen Petronio Company from 2006 through
                2010. She is a founding member of Second Story Dance Project in
                Westchester, NY where she also lives with her husband and 2
                children.
              </p>
            </div>
          </div>
        </section>
        <section>
          <Link to="/generic" className="image">
            <img src={melissa} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Melissa Gingold</h3>
                <h4>Teacher</h4>
              </header>
              <p>
                <b>Melissa Gingold</b> grew up in New Rochelle. She began ballet
                classes at age 11 at the Westchester Theatre of Dance at the JCC
                in Mid-Westchester, where in 2012 she received the Maria Jones
                Scholarship Award for excellence and promise in the field of
                dance. In the PAVE program at New Rochelle High School, she
                studied and performed many styles including hip-hop,
                contemporary and modern dance. She received instruction at
                Central Pennsylvania Youth Ballet’s summer intensive program in
                2015. Melissa graduated from Cornell University with a degree in
                Nutritional Sciences and a dietetics concentration. At Cornell,
                she performed and choreographed lyrical and contemporary pieces
                with a talented group of students in Pandora Dance Troupe. Up to
                500 audience members attended their shows in large performance
                halls on campus. Melissa currently works as a registered
                dietitian, and continues to dance around Westchester and NYC.
                She is honored and excited to guest-teach at the Coleman School
                of Dance!
              </p>
            </div>
          </div>
        </section>
                  
        <section>
          <Link to="/generic" className="image">
            <img src={susanna} alt="" />
          </Link>
          <div className="content">
            <div className="inner">
              <header className="major">
                <h3>Susanna Procario-Foley</h3>
                <h4>Teacher, Alumna</h4>
              </header>
              <p>
                <b>Susanna Procario-Foley</b> danced with the Coleman School of
                Dance for 13 years, where she studied ballet, modern,
                contemporary, improvisation, and choreography. She now attends
                Connecticut College where she double majors in dance and
                English. In 2019, Susanna taught at CSD’s summer camp,
                choreographing and producing performances for the kids. She
                loves teaching because it allows her to spread her passion for
                creativity to younger dancers and watch them grow as artists and
                people. Susanna believes that dance is a powerful means of
                expression and should not be undervalued.
              </p>
            </div>
          </div>
        </section>
      </section>
    </div>
  </Layout>
)

export default Faculty
